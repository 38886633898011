.product-card-minimal {
  margin: -0.5px;

  @apply flex flex-col relative border;

  .price {
    @apply mt-3  text-xs;
  }

  &--thumb {
    @apply z-0;
  }

  &--title {
    @apply m-0 !important text-xs;
  }

  &--link {
    @apply block no-underline text-current;

    &::after {
      @apply block absolute inset-0 z-20;
      content: '';
    }
  }

  &--details {
    @apply p-3 border-t;
  }

  &--option {
    @apply relative z-30 mt-4;

    .option {
      @apply m-0 p-0 border-0;

      &--values {
        @apply mx-auto;
      }
    }
  }
}

.product-card-minimal .product-card--photo {
  @apply relative overflow-hidden;
  height: 50vw;
  .is-hover {
    @apply absolute inset-0 z-10 opacity-0 invisible transition-all;
  }
}

.product-card-minimal:hover .is-hover {
  @apply opacity-100 visible;
}
