----- Mixins ----- .is-hashed {
  background-image: repeating-linear-gradient(
    -45deg,
    hsla(var(--pageText-hsl), 0.5) 0,
    hsla(var(--pageText-hsl), 0.5) 1px,
    transparent 0,
    transparent 50%
  );
  background-size: 8px 8px;
  background-repeat: repeat;
}

/* ----- Counters ----- */

.counter {
  /* @apply border rounded-full p-2 bg-pageBG text-pageText; */

  &.is-small {
    @apply p-1;
  }

  &.is-inverted {
    @apply bg-transparent text-pageBG;
  }

  .product--actions & {
    @apply mr-4;
  }

  &--up,
  &--down {
    @apply -my-px w-8 h-8 p-2 rounded-full transition-colors duration-300;

    .cart & {
      @apply my-0;
    }

    &:hover {
      background: hsla(var(--pageText-hsl), 0.2);

      .is-inverted & {
        background: hsla(var(--pageBG-hsl), 0.2);
      }
    }
  }

  &--amount {
    @apply text-xs font-mono;

    .cart & {
      @apply w-8;
    }

    input {
      @apply p-1;
    }
  }
}

.square-item--caption span {
  @apply mix-blend-normal;
  background-color: #f26426;
  color: inherit;
  padding: 0.2rem 0.5rem;
}
