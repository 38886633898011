.promo-bar {
  @apply relative z-60 bg-pageBG text-center whitespace-nowrap overflow-x-auto overflow-y-hidden border-b;
  scroll-behavior: smooth;

  &--content {
    @apply inline-flex px-8 py-2 text-xs;
  }

  &--link {
    @apply block;
  }
}
