.collection {
  @apply overflow-hidden;

  &--grid {
    @apply grid grid-cols-2 md:grid-cols-3 -m-px;
  }

  &--pagination {
    @apply relative p-4 sm:py-8 bg-pageBG border-t text-center;
  }
}

.collection-count {
  @apply hidden relative ml-2 leading-none;
  vertical-align: super;
  font-size: 0.5em;

  /* Only show product counts for the mega-nav */
  .mega-nav & {
    @apply inline-block;
  }
}

.product-card {
  @apply flex flex-col relative;

  .collection &,
  .marquee--product & {
    @apply pb-8;
    margin: -0.5px;
  }

  &.is-inline {
    @apply my-4;
  }

  &--thumb {
    @apply z-0;
  }

  &--photo {
    @apply relative overflow-hidden;
    height: 400px;

    @media (--md) {
      height: 600px;
    }

    .is-hover {
      @apply absolute inset-0 z-10 opacity-0 invisible transition-all;

      .product-card:hover & {
        @apply opacity-100 visible;
      }
    }
  }

  &--gallery {
    @apply relative;

    .carousel {
      &--hud {
        @apply z-30;
      }
    }
  }

  &--details {
    @apply text-center;

    .product-card--visuals + & {
      @apply mt-6;

      .is-inline & {
        @apply mt-4;
      }
    }
  }

  &--title {
    @apply m-0 !important text-xs;

    .is-featured & {
      @apply text-base;
    }

    .is-inline & {
      @apply text-sm;
    }

    + .price {
      @apply mt-2;
    }
  }

  &--link {
    @apply block no-underline text-current;

    &::after {
      @apply block absolute inset-0 z-20;
      content: '';
    }
  }

  &--option {
    @apply relative z-30 mt-4;

    .option {
      @apply m-0 p-0 border-0;

      &--values {
        @apply mx-auto;
      }
    }
  }
}

.price {
  &--discount {
    @apply ml-2 p-1 rounded-sm bg-pageText text-pageBG text-xxs leading-tight tracking-widest font-semibold uppercase;
  }
}

.product-featured {
  @apply flex flex-col relative;

  h2 {
    @apply mt-4 text-base font-semibold text-center transition-opacity duration-200;

    .product-featured:hover & {
      @apply opacity-100 visible;
    }
  }

  &:hover {
    h2 {
      @apply opacity-40;
    }
  }
}
