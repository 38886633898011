html {
  &.is-loading,
  &.is-loading * {
    @apply cursor-wait !important;
  }
  scroll-behavior: smooth;
}

body {
  @apply flex flex-col;
}

.section {
  @apply relative p-4 sm:p-8;

  &--content {
    @apply mx-auto;
  }
}

#content > section {
  @apply border-t border-black;

  &:first-child {
    @apply border-0;
  }
}

.section-title {
  @apply container mx-auto py-10;
}
