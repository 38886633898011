.quote {
  @apply p-5 text-center;

  &__wrapper {
    @apply my-20;
  }

  p {
    @apply font-extralight text-xl sm:text-3xl lg:text-xl leading-relaxed sm:leading-relaxed lg:leading-relaxed;
  }
}
